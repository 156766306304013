<template>
  <div class=" myflex-column cover min-pb">
    <div class="spacer"></div>
    <div class="nocenter cover-heading usefont paddup">
      <h1 class="cover-heading usefont">
        Mentions légales : LEFLOCH Métal Concept
      </h1>
      <p>
        SASU au capital de 5 000 € <br>
        RCS Macon 898 927 603 <br>
        Code APE 4332A <br>
        N° TVA : FR73 898 927 603 <br>
      </p>
      <p>
        Siège social : 11 Rue de la Dombe - 71160 Digoin - France <br>
        Contact : contact@lefloch-metalconcept.fr<br>
        Téléphone : 0385811454<br>
      </p>
      <p>
        Ce site est hébergé par OVH SAS 2 rue Kellermann - 59100 Roubaix - France
      </p>

      <h3>Conditions d'utilisation du site Internet www.lefloch-metalconcept.fr</h3>
      <p>
        Le site et chacun des éléments, y compris mais sans limitation les marques, les logos, icônes, infographies,
        photographies, qui le composent sont protégés au titre de la législation internationale de la propriété
        intellectuelle. Les contenus figurant sur le site sont la propriété de Lefloch Metal Concept ou d’autres
        entreprises. Toute utilisation, reproduction ou représentation, par quelque procédé que ce soit, et sur quelque
        support que ce soit, de tout ou partie du site et/ou des éléments qui le composent n'est pas autorisée sans le
        consentement expresse de Lefloch Metal Concept.<br>
        Toutes les marques qui figurent sur le présent site internet sont la propriété de leurs titulaires respectifs.
      </p>

      <h1>Politique d'utilisation des cookies</h1>
      <p>
        D'une façon générale, vous pouvez visiter notre site sur Internet sans avoir à décliner votre identité et à
        fournir des informations personnelles vous concernant. Cependant certains de nos services et outils necessitent votre consentement à l'exploitation de cookies pour fonctionner. <br>
      </p>
      <h4>Qu'est-ce qu'un cookie ?</h4>
      <p>
        Un Cookie est dit « propriétaire » ou « tiers » selon le domaine dont il émane. Notre site n'exploite que des Cookies tiers. Les Cookies tiers sont des Cookies installés par un domaine autre que celui du Site (à titre d’exemple, un cookie peut être déposé par une régie publicitaire tierce sur le Site). Lorsqu’un Utilisateur consulte le Site et qu’une autre entité installe un Cookie par l’intermédiaire du Site, ce Cookie est un Cookie tiers.<br>

        Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet de LEFLOCH Metal-Concept. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que le site LEFLOCH Metal-Concept dépose ce type de cookies. <br>
        L’Utilisateur peut se rendre sur le site internet de la CNIL afin de recueillir des informations concernant les cookies via notamment la page suivante : <a target="_blank" href="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs.">https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs.</a><br> 
      </p>
      <p> Vous pouvez à tout moment révoquer ce consentement en cliquant sur le bouton ci-dessous.</p>


    </div>
    <p class="lead paddup usefont">
        <a @click="removeCookie" class="btn btn-secondary"> Réinitialiser les préférences de cookies !
        </a>
      </p>
  </div>
</template>
<script>
export default {
  name: 'LegalNotice',
  methods: {
    removeCookie () {
      console.log('removeCookie')
      this.$emit('removeCookie')
    }
  },
  metaInfo: {
    title: 'Mentions légales et Cookies',
    titleTemplate: '%s | LEFLOCH Métal Concept'
  }
};
</script>
<style lang="css">
.nocenter {
  text-align: left;
}

.myflex-column {
  display: flex !important;
  min-height: fit-content;
  flex-direction: column;
}

@media (max-width: 576px) {
  .myflex-column {
    display: block !important;
    ;
  }

  .informations {
    padding-top: 10px;
  }
}

.paddup {
  padding-top: 5px;
}

.min-pb {
  padding-bottom: min(25px, 5%);
}</style>
  